import React from 'react'
import Seo from "../../components/seo"

const TermsOfUse = () => {
  return (
    <div className="legal">
      <h1>Terms of Service</h1>
      <p>These Terms and Conditions of Service (the “Terms”) and our Privacy Policy govern your access to and use of the Dockovpn services and the Dockovpn website so please read them carefully.</p>

      <p><strong>Effective date: July 31, 2024</strong></p>

      <p>By using Dockovpn, you are agreeing to these Terms and the use of your Personal Information as described in our Privacy Policy. If you do not agree to these Terms, do not use Dockovpn. If you are using Dockovpn on behalf of an organization such as your employer, you are agreeing to these Terms on behalf of that organization, and represent and warrant that you have the authority to agree to these Terms on the organization's behalf. In that case, “you” and “your” will refer to that organization.</p>

      <p>We may periodically revise the Terms. If a revision is material, as determined solely by us, we will notify you for example via email. The current version of our Terms will always be posted on our Terms page, so please check back regularly. By continuing to use Dockovpn after revisions become effective, you are agreeing to the revised Terms. If you do not agree to the revised Terms, please stop using Dockovpn.</p>

      <h2>1. Your Dockovpn Account</h2>
      <p>To use Dockovpn, you may need to create an account.</p>
      <p>You are responsible for safeguarding your Dockovpn login credentials. You are responsible for activity on your account, whether or not you authorized that activity. You should immediately notify us of any unauthorized use of your account.</p>
      <h4>Service Account Information</h4>
        <p>We collect information such as your username, authentication tokens and other necessary security information required for authentication and access to our services.</p>

        <h2>2. Private beta program</h2>
      <p>By signing up to Dockovpn private beta you understand that Dockovpn platform is a beta software which implies that the system may produce errors and issues which may result in the loss of data.
      Dockovpn has the right to accept or reject participation in private beta programs at its sole discretion. The amount and number of rewards will be decided for each selected project separately.
          Dockovpn reserves the right not to accept any submitted project to participate in a private beta program.</p>

        <h2>3. Intellectual Property</h2>
        <p>The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of Dockovpn and its licensors.</p>
        <h2>4. Limitation of Liability</h2>
      <p>Notwithstanding any damages that You might incur, the entire liability of Dockovpn and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service for the last 3 months or 20 USD if You haven't purchased anything through the Service.
      To the maximum extent permitted by applicable law, in no event shall Dockovpn or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if Dockovpn or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
          Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

        <h2>5. Your Use of Dockovpn</h2>
        <p>You may only use Dockovpn as permitted by law, including all applicable federal, state, local or international laws and regulations. Do not, for example:</p>
        <ul>
        <li><p>Use any engine, software, tool, agent, device, mechanism or the like to access, search, or download intellectual property from Dockovpn, or use Dockovpn in any way other than through our publicly supported interfaces;</p></li>
      <li><p>Access, tamper with, or use non-public areas of Dockovpn, Dockovpn’s computer systems, or the technical delivery systems of Dockovpn’s providers;</p></li>
      <li><p>Probe, scan, or test the vulnerability of any Dockovpn system or network or breach any security or authentication measures;</p></li>
      <li><p>Decipher, decompile, disassemble or reverse engineer any of the software used to provide Dockovpn;</p></li>
      <li><p>Plant malware or use Dockovpn to distribute malware;</p></li>
      <li><p>Violate the privacy of others;</p></li>
      <li><p>Violate any applicable law or regulation;</p></li>
      <li><p>Impersonate or misrepresent your affiliation with any person or entity; or post or transmit anything that is fraudulent or misleading;</p></li>
      <li><p>Send unsolicited communications, promotions, advertisements or spam or otherwise infringe on others' rights;</p></li>
      <li><p>Interfere with the access of any user, host or network, including introducing any virus to, overloading, flooding, spamming, or mail-bombing Dockovpn, or introducing any other material or content which is malicious or technologically harmful;</p></li>
      <li><p>Attack Dockovpn via a denial-of-service attack or a distributed denial-of-service attack; or otherwise attempt to interfere with the proper working of Dockovpn;</p></li>
      <li><p>Attempt any of the above, or encourage or enable any other individual to do any of the above.</p></li>
        </ul>
      <p>We have the right to investigate violations of these Terms and may terminate your Account for non-compliance with these Terms.</p>

      <h2>6. Indemnity</h2>
      <p>You will hold harmless and indemnify Dockovpn and its affiliates, officers, directors, employees, contractors, agents, licensors, and suppliers from and against any claim, suit or action arising from or related to the use of Dockovpn or violation of these Terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.</p>

      <h2>7. Termination</h2>
      <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
      Upon termination, Your right to use the Service will cease immediately.
        If You wish to terminate Your Account, You may simply discontinue using the Service.</p>

      <h2>8. Terms of Paid Subscriptions</h2>
      <p>If you purchase a subscription to the Services via the Dockovpn website (“Online Subscription”) or pursuant to any ordering document (“Order Form Subscription”), the following terms of subscription will apply to you:</p>
      <h3>8.1 Subscription Term</h3>
      <p>The Services are provided on a subscription basis for a term defined in the Online Subscription, or on the Order Form Subscription, as applicable (each, a “Subscription Term”).</p>
      <h3>8.2 Cancellation</h3>
      <p>You may cancel your subscription at any time; however, you are responsible for advance payment of the entire Subscription Term. Fees are non-refundable based on the Subscription Term purchased and not actual usage. Payment obligations for the Subscription Term to which you subscribe are noncancelable.</p>
      <h3>8.3 Renewals</h3>
      <p>For Online Subscriptions, each Subscription Term will automatically renew for an additional Subscription Term equal in length to the original Subscription Term. Online Subscriptions can be canceled in User Dashboard at login.dockovpn.io
        For Order Form Subscriptions, each Subscription Term will automatically renew for an additional Subscription Term equal in length to the original Subscription Term, unless cancellation is requested in writing (including by email to support@dockovpn.io) at least thirty (30) days prior to the expiration of the then-current Subscription Term.</p>
      <h3>8.4 Billing and Payment</h3>
      <p>If you purchase a subscription to the Services via credit card, debit card or other payment card (collectively, “Credit Card”), you hereby authorize Dockovpn (or its designee) to automatically charge your credit card in accordance with the applicable Online Subscription or Order Form Subscription. You acknowledge that certain credit cards may charge you foreign transaction fees or other charges. If your payment is not successfully settled for any reason, you remain responsible for any amounts not remitted to Dockovpn. Each net new user beyond the scope of the initial Online Subscription or Order Form Subscription, as applicable, will incur an additional charge, and will be included in a true-up invoice or charged automatically via credit card, as applicable.</p>
      <h3>8.7 Taxes and Fees</h3>
      <p>You are responsible for all sales, use, value added or other taxes of any kind, other than taxes based on Dockovpn’s net income. You are also responsible for any payment-related fees such as wire transfer or Credit Card processing fees.</p>
      <h3>8.8 Expenses</h3>
      <p>You are responsible for all fees or expenses related to accessing or using the Services that are extrinsic to the Services. This includes, without limitation, your own internet service provider fees.</p>
    </div>
  )
}

export const Head = () => <Seo title="Terms of Use" />

export default TermsOfUse